import * as React from "react"
import Footer from "../components/footer"
import Header from "../components/header"
import * as styles from "./donate.module.scss"
import Layout from "../components/layout"

export default function Donate() {
  return (
    <Layout title="Donate | Foundation 649">
      <Header />
      <div className={styles.donate}>
        <h2>Partner with Foundation 649</h2>
        <p className={styles.donateIntro}>
          By making a contribution to the Foundation 649, you are supporting
          young, talented Asian Americans who are actively shaping our cultural
          and social identity.
          <a
            href="https://www.paypal.com/donate?hosted_button_id=QPTD2JAXRCDXU"
            className={styles.donateBtn}
          >
            Donate Securely
          </a>
          Foundation 649 is a 501(c)3 nonprofit. Your contributions are
          tax-deductible to the extent permitted by law (EIN: 86-2090615).
        </p>
      </div>
      <Footer />
    </Layout>
  )
}
